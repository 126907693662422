import React from 'react';
import {useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {db} from "./firebase-config";
import { addDoc, collection } from 'firebase/firestore';
import axios from 'axios';
 

function Searchbox(props) {

    const [searchValue, setSearchValue] = useState();
    const [searchDate, setSearchDate] = useState();
    const [items, setItems] = useState([]);
    // const searchCollectionRef = collection(db, "Searcholl");

    // const [searchTerms, setSearchTerms] = useState([]);


    const postSearch = async(value, date) => {
       console.log(value, date);

      //  await addDoc(searchCollectionRef, {
      //   terms:  value,
      //   Date: date
      //  });
       try{
        const response = await axios.post('https://server.taskticks.com/api/items/searcholl', {
        terms:  value,
        Date: date
        });
        setItems([...items, response.data]);
      } catch (error) {
        console.error(error);
      }


    }



     const navigate = useNavigate();
     useEffect(()=> {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const qValue = urlParams.get('q');
      setSearchValue(qValue);
      console.log(qValue);
      if(qValue) {
        search();
      }
    }, [])
    const enteredSearch = (e) => {
      setSearchValue(e.target.value);
      console.log(e.target.value);
    }
    const search = (event) => {
        //  console.log(event);
       if(event) {
         if(event.key === 'Enter') {
           // console.log("enter works");
           let searchYahoo = document.getElementById('searchYahoo');
           // console.log(searchYahoo.value);
            let SYvalue = searchYahoo.value.trim();
            if(SYvalue.length === 0){
              return false;
            }
           
            let SearchedDate = new Date();
            // setSearchDate(Date);
            postSearch(SYvalue, SearchedDate);
            // return false;
            // https://fwd.searchnet.info/f?cid=15172&q=kw
           // navigate('/search?q='+ SYvalue);
            // console.log(queryString);
            const url = `/search?q=${SYvalue}`;
             window.history.pushState(null, null, url);
            //  let passLink = 'http://af.xdock.co/?pid=21000&subid=210002&keyword='+ SYvalue;
                let passLink = 'https://www.google.com/search?q='+ SYvalue + '&safe=active';
             console.log(passLink);
            //  return false;
            setTimeout(() => {
             //  navigate(passLink, { replace: true });
              window.open(passLink, '_target', 'noreferrer');
            }, 500)
            
            
          }
        }else {
         // let searchYahoo = document.getElementById('searchYahoo');
         // // console.log(searchYahoo.value);
         //  let SYvalue = searchYahoo.value;
         const queryString = window.location.search;
         const urlParams = new URLSearchParams(queryString);
         const qValue = urlParams.get('q');
         let SearchedDate = new Date();
         // setSearchDate(Date);
         postSearch(qValue, SearchedDate);
           navigate('/search?q='+ qValue);
          // console.log(queryString);
          const url = `/search?q=${qValue}`;
           window.history.pushState(null, null, url);
           let passLink = 'https://www.google.com/search?q='+ qValue + '&safe=active';
          //  let passLink = 'http://af.xdock.co/?pid=21000&subid=210002&keyword='+ qValue;
         console.log(passLink);
        //  return false;
          setTimeout(() => {
           //  navigate(passLink, { replace: true });
            window.open(passLink, '_self', 'noreferrer');
          }, 500)
       }
        
       
       }
    
    return (
        <div>
            <input onKeyDown={search} id='searchYahoo' className='searchInput'  onChange={enteredSearch} value={searchValue} type="search" placeholder="Search the web" />
        </div>
    );
}

export default Searchbox;